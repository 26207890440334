import React from "react";
import NoPage from "../components/NoPage";

const NotFound = () => {
  return (
    <div>
      <NoPage />
    </div>
  );
};

export default NotFound;
