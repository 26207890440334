import React from "react";
import Peringatan from "../components/Peringatan";

const PeringatanDini = () => {
  return (
    <div className="animated">
      <Peringatan />
    </div>
  );
};

export default PeringatanDini;
