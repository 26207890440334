import React from "react";
import Ilmu from "../components/Ilmu";

const Edukasi = () => {
  return (
    <div className="animated">
      <Ilmu />
    </div>
  );
};

export default Edukasi;
