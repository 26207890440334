import React from "react";
import Alat from "../components/Alat";

const Peralatan = () => {
  return (
    <div>
      <Alat />
    </div>
  );
};

export default Peralatan;
